/* 
general 
-------
*/
body {
  background: #545454;
  margin-top: 0px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  min-height: 100vh;
  overflow-x: hidden;
}
.ant-drawer-body {
  background: linear-gradient(360deg, rgb(113, 113, 113) 0%, rgb(84 84 84) 100%);
}
.ant-anchor-wrapper {
  background: linear-gradient(360deg, rgb(50 13 13) 0%, rgb(84 84 84) 100%);
  border-radius: 10px;
  margin-bottom: 10px;
}
.ant-spin-dot-item {
  background-color: #fff !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: large;
  color: #400303 !important;
}
p {
  color: #fff;
  font-size: medium;
  margin: 0 auto;
  line-height: 1.8;
  letter-spacing: 1px;
  animation: fadeInUp 1.2s ease-in-out;
}
h1,h2,h3,h4,h5,h6 {
  color: #fff;
}
.faqBlock span{
  color: #fff !important;
}
.ant-collapse-header-text {
  font-size: large;
}

/* .container-fluid {
  margin: 0 5% auto 5%;
} */

.ant-layout {
  background: #fff !important;
}

.ant-layout-content {
  background: #565252;
}

.mainLayout .ant-layout-header {
  background: #7f7c7c;
  padding: 0;
  height: auto;
  line-height: 1.6;
}

.bgGray {
  background: linear-gradient(360deg, rgb(113, 113, 113) 0%, rgb(84 84 84) 100%);
}

/* 
title holder
------------
*/
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 5px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: '';
  background: #1890ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
  color: #fff;
}
/* 
header
------ 
*/
.ant-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 3px;
}

.header .logo {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
}

.logo-text {
  background-color: rgb(255, 255, 255);
  font-family: math;
  color: #cf0c05;
  font-size: 28px;
}

.header .logo .fas {
  color: #1890ff;
  margin: 0 5px 0 0;
  font-size: 28px;
}

.header .logo a {
  color: #fff;
  font-family: math;
  font-size: 28px;
  font-weight: 600;
}

.ant-anchor-ink-visible{
  display: none !important;
}

.ant-anchor-wrapper .ant-anchor .ant-anchor-link {
  padding-inline: 16px 16px;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
}

.header .ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

.header .ant-anchor-link:before,
.header .ant-anchor-link:after {
  content: '';
  background: #1890ff;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-anchor-link:after {
  left: auto;
  right: 30%;
}

.header .ant-anchor-link.ant-anchor-link-active:before,
.header .ant-anchor-link:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-anchor-link.ant-anchor-link-active:after,
.header .ant-anchor-link:hover:after {
  right: 5%;
  opacity: 1;
}

/* ant menu */
.nav-link {
  color: black;
  font-size: 18px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
.header .ant-menu {
  border: 0;
  background: none !important;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  color: #333;
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: '';
  background: #6a1e1e;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: #1890ff;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}

/* 
hero
---- 
*/
.homeBlock {
  margin: 10px;
  background: linear-gradient(360deg, rgb(113, 113, 113) 0%, rgb(84 84 84) 100%);
  border-radius: 5px;
}
.heroBlock {
  background: url('./assets/images/back4.webp') no-repeat;
  background-position: 50% 100%;
  background-size: cover;
  height: 100vh;
}

.heroBlock .container-fluid {
  display: block !important;
}
.slider-para {
  min-height: 80px;
  margin-top: -11%;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-left: 5px;
  margin-right: 15px;
  background: linear-gradient(335deg, rgb(168 155 155 / 62%) 0%, rgb(100 97 97 / 57%) 100%);
}
.main-blog-desp {
  margin-top: -5px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background: linear-gradient(335deg, rgb(168 155 155 / 62%) 0%, rgb(100 97 97 / 57%) 100%);
  border-radius: 5px;
}

@keyframes fadeBlink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blink {
  animation: fadeBlink 2s steps(1, start) infinite;
  ;
  text-align: center;
  color: rgb(18, 62, 57);
  font-weight: bold;
}

.aboutBlock {
  background: linear-gradient(360deg, rgb(113, 113, 113) 0%, rgb(84 84 84) 100%);
}

.aboutBlock .content {
  margin: 10px;
  border: 1px solid #c8c4c4;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}

.heroBlock .slick-dots li button {
  background: #000 !important;
}

.heroBlock .slick-dots li.slick-active button {
  background: #1890ff !important;
}

.heroBlock .content {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 70px;
  margin: 20px;
}

.heroBlock h3 {
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 30px;
  color: #fff;
  text-align: center;
}

.heroBlock p {
  color: #f1f1f1;
}

.inner-content {
  margin: 0 0 30px;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}

/* 
block
----- 
*/
.block {
  border-bottom: 1px solid #d9d9d9;
}
.block-anchor {
  display: block;
  position: relative;
  top: 300px;
  visibility: hidden;
}

/* 
about
----- 
*/
.aboutBlock .contentHolder {
  text-align: start;
  margin: 0 0 50px;
}

.points {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutBlock .icon {
  font-size: 30px;
  margin: 0 0 10px;
}

.infoCard {
  background: linear-gradient(360deg, rgb(95, 91, 91) 0%, rgb(7 4 4) 100%);
}
.ant-card-head-title {
  color: #fff;
}

.ant-card-body span {
  font-size: medium;
  color: #fff;
}

/* 
how it works
------------
*/
.worksBlock {
  background: url('./assets/images/bg-hero.jpg') no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}

.worksBlock:before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.worksBlock h2 {
  color: #fff;
}

.worksBlock .contentHolder {
  text-align: center;
}

.worksBlock .contentHolder button {
  font-size: 18px;
  line-height: 1;
  background: #7f7c7c;
  border: 1px solid #fff;
  color: #fff;
}

#faq {
  scroll-margin-top: 100px !important; /* Adjust as needed */
}

.main-details {
  border: 1px solid #640a0a;
  border-radius: 10px;
}

.flex-display {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.details {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px
}
.streamers {
  list-style:circle;
}
.sport-image {
  height: 70px;
  width: 70px !important;
}

.category .ant-anchor-link-title {
  color: #000 !important;
}
/* App.css */
.list-container {
  max-width: 600px;
  margin: 25px auto;
  background: linear-gradient(360deg, rgb(255 255 255) 0%, rgb(7 4 4) 100%);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.list-header {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.list-item {
  background: linear-gradient(360deg, rgb(42 32 33) 0%, rgb(14 14 14) 100%);
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
  transition: transform 0.3s;
}

.list-item:hover {
  transform: scale(1.02);
  background: linear-gradient(360deg, rgb(62, 44, 44) 0%, rgb(70, 22, 22) 100%);
  border-left: 4px solid #140101;
}

.list-item {
  position: relative;
  padding-left: 25px; /* Space for the dot */
}

.list-item::before {
  content: '';
  position: absolute;
  left: 10px; /* Position of the dot */
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background: linear-gradient(360deg, rgb(204, 0, 11) 0%, rgb(70, 22, 22) 100%); /* Custom color for the dot */
  border-radius: 50%;
}

.ant-collapse-content-box {
  background: linear-gradient(360deg, rgb(113, 113, 113) 0%, rgb(73, 52, 52) 100%);
}


/* 
faqs
----
*/
.flex-display {
  background: linear-gradient(360deg, rgb(113, 113, 113) 0%, rgb(84 84 84) 100%);
}
.faqBlock .ant-collapse {
  margin: 0 0 40px;
}

.faqBlock .quickSupport {
  text-align: center;
  max-width: 740px;
  margin: auto;
}

.faqBlock .fas {
  margin: 0 10px 0 0;
}

/* 
pricing block
-------------
*/
.pricingBlock .ant-list {
  text-align: center;
}

.pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
  box-shadow: 0 0 12px 2px #f3f3f3;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
}

.pricingBlock .ant-card-head-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
}

.pricingBlock .large {
  font-size: 38px;
  line-height: 1;
  margin: 0 0 20px;
}

.pricingBlock .fab {
  margin: 0 10px 0 0;
}

/* 
contact
-------
*/
.contactBlock .ant-form {
  max-width: 640px;
  margin: auto;
}

.contactBlock .ant-form textarea {
  min-height: 120px;
  height: 120px;
}

/* 
footer
------
*/
.ant-layout-footer {
  background: #111 !important;
  color: #fff !important;
  padding: 60px 0 !important;
  text-align: center;
}

.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.footer .fas {
  color: #1890ff;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fff;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #fff;
}

.footer .socials a:hover {
  color: #1890ff;
}

.footer .goTop:hover {
  opacity: 0.7;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

/* 
responsive
----------
*/
@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}

@media only screen and (max-width: 767px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1);
  }

  .mobileVisible {
    display: block;
  }

  .mobileHidden {
    display: none;
  }

  .heroBlock h3 {
    font-size: 20px;
    line-height: 1.2;
    margin: 0 10px 5px;
  }
  .inner-content {
    margin: 0 10px 20px;
  }
}
