.parallelogram {
    color: #f13939;
}
.ant-layout-header {
    line-height: .5 !important;
}
.header {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
}
.logo {
    margin-right: 2%;
    margin-left: 2%;
}
.parallelogram span {
    display: inline-block;
}
.ant-anchor-link-title {
    color: #ffffff !important;
}
.ant-anchor-link-title-active {
    color: #070101 !important;
}